<template>
  <div v-if="entity && entity.information">
    <div class="services-header">
      <h1>Services</h1>
      <div class="mr-4 d-none d-sm-block kart" v-if="services.length > 0">
        <div style="">
          <p>Signin or create an account to proceed with any purchase.</p>
        </div>
      </div>
    </div>

    <div v-if="entity.services && services.length" class="grid-container">
      <shared-service-or-room-card
        class="service-card"
        v-for="(service, index) of services"
        :key="index"
        :service="service"
        :is_vehicle="service.is_vehicle"
        :is_anonymouse="true"
      />
    </div>
    <div v-else>
      <h4>{{ entityName }} has no Services yet</h4>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { GET_ENTITY } from "@/store/actions";

import SharedServiceOrRoomCard from "@/components/tourist/SharedServiceOrRoomCard.vue";

export default {
  components: {
    SharedServiceOrRoomCard,
  },
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      entity: {},
      currentService: {},

      kart: [],
      cachedKart: [],

      showPaymentModal: false,
      showRentalPaymentModal: false,
      showCashPaymentNotification: false,

      cashPaymentCode: "",
      transactionId: "",
    };
  },
  computed: {
    services() {
      if (this.entity && this.entity.services) {
        const services = this.entity.services.map((serviceOrRoom) => ({
          ...serviceOrRoom,
          type: "service",
          is_vehicle:
            serviceOrRoom.vehicle_year &&
            serviceOrRoom.vehicle_model &&
            serviceOrRoom.vehicle_model.length > 0,
        }));

        let rooms = this.getValueFromSource(this.entity, "rooms", []);
        rooms = rooms.map((serviceOrRoom) => ({
          ...serviceOrRoom,
          type: "room",
        }));

        return [...services, ...rooms].filter((serviceOrRoom) => {
          const is_active_service = serviceOrRoom.status === "active";
          if (serviceOrRoom.is_vehicle) {
            return is_active_service && serviceOrRoom.rented === false;
          }

          if (serviceOrRoom.type === "room") {
            return is_active_service && serviceOrRoom.fully_booked === false;
          }
          return is_active_service;
        });
      }

      return [];
    },
    entityId() {
      return this.$route.params.id;
    },
    entityName() {
      return this.getValueFromSource(this.entity, "information.name");
    },
  },
  created() {
    this.fetchEntity();
  },
  methods: {
    ...mapActions("tourist", {
      getEntity: GET_ENTITY,
    }),
    ...mapMutations("tourist", {
      setTouristState: "SET_STATE",
    }),
    async fetchEntity() {
      try {
        this.loading = true;

        const response = await this.getEntity({
          entity_type: this.entity_type,
          entity_id: this.$route.params.id,
        });

        this.entity = response.entity;
      } catch (error) {
        this.$notification.error({ message: "Error fetching entity data" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: auto;
}

.service-card {
  /* margin-left: 5px; */
  margin-right: 25px;
}

.services-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 130px;
}
</style>
